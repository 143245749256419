


#home {
 
  padding-top: 100px;
  /* padding-bottom: 150px; */
}
.fn_cs_slider {
  padding: 0 20px;
  overflow: hidden;
}
.fn_cs_counter_list li:nth-of-type(4n) .divider,
.fn_cs_roadmap .step_in .fn__svg,
.fn_cs_slider .hidden_list,
.metaportal_fn_boxed_countdown li:last-child span:after,
.metaportal_fn_boxed_countdown li:last-child span:before,
.metaportal_fn_leftnav .nav_holder .icon,
.metaportal_fn_leftnav.ready,
.metaportal_fn_leftnav_closer.ready,
.metaportal_fn_wallet_closer.ready,
.metaportal_fn_walletbox.ready {
  display: none;
}
.slider_nav {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 52px;
}
.slider_nav > * {
  margin-right: 100px;
}
.fn_cs_section_divider .divider > :last-child,
.slider_nav a > :last-child,
.slider_nav > :last-child {
  margin-right: 0;
}
.slider_nav:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -0.5px;
}
.fn_cs_slider .slider_top .item_in,
.slider_nav .circle,
.slider_nav .icon {
  background-color: transparent;
}
.slider_nav a {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  padding: 10px 0;
}
.metaportal_fn_result_box .clear_all,
.slider_nav a > * {
  margin-right: 10px;
}
.slider_nav .circle {
  width: 12px;
  height: 12px;
  display: block;
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
  border-radius: 100%;
}
.slider_nav .icon {
  width: 34px;
  height: 34px;
  display: block;
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
  border-radius: 100%;
}
.slider_nav .fn__svg {
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  color: rgba(255, 255, 255, 0.5);
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
}
.slider_nav .prev .fn__svg {
  transform: rotate(90deg);
  margin-left: -8px;
}
.slider_nav .next .fn__svg {
  transform: rotate(-90deg);
  margin-left: -5px;
}
.fn_cs_slider .slider_top > img {
  width: 33.3333% !important;
  min-width: 33.3333% !important;
  opacity: 0;
}
.fn_cs_slider .slider_top {
  padding: 59px 0 60px;
  position: relative;
}
.fn_cs_slider .slider_top ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}
.fn_cs_about,
.fn_cs_counter_list ul,
.fn_cs_desc {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
}
.fn_cs_slider .slider_top li {
  width: 33.3333%;
  position: absolute;
  margin: 0;
  transform: translateX(100%) scale(0);
  transition: none;
  opacity: 1;
  top: 65px;
}
.fn_cs_slider .slider_top li.next2 {
  left: 100%;
  transform: translateX(100%) scale(0);
  transition: 1s;
}
.fn_cs_slider .slider_top li.prev2 {
  left: 0;
  transform: translateX(-100%) scale(0);
  transition: 1s;
}
.fn_cs_slider .slider_top li.active,
.fn_cs_slider .slider_top li.next,
.fn_cs_slider .slider_top li.prev {
  transform: translateX(0) scale(1);
  opacity: 1;
  transition: 1s;
}
.fn_cs_slider .slider_top li.next,
.fn_cs_slider .slider_top li.prev {
  cursor: pointer;
}
.fn_cs_slider .slider_top li.prev {
  left: -40px;
}
.fn_cs_slider .slider_top li.next {
  left: 66.6666%;
  margin-left: 40px;
}
.fn_cs_slider  img {
  min-width: 100%;
  opacity: 0;
  max-height: 334px;
}
.fn_cs_slider .slider_top .item {
  perspective: 1000px;
  perspective-origin: 50% 50%;
  max-width: 458px;
}
.fn_cs_slider .slider_top .item_in {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0.85) scaleY(0.85) rotateY(180deg);
}
.fn_cs_slider .active .metaportal_fn_videobutton {
  opacity: 0;
  visibility: visible;
}
.fn_cs_slider .slider_top .img {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
}
.fn_cs_slider .slider_top .img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
}

.fn_cs_slider .slider_top .item_in:after,
.frenify_cards_gallery .img_holder:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--mc1);
  opacity: 0.15;
  border-radius: 20px;
  box-shadow: 0 5px 20px;
}
#fun_facts,
.blog__item,
.fn_cs_about .left_part .bg_overlay,
.metaportal_fn_mintbox {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
}
.fn_cs_slider .slider_top .item_in:before {
  height: 15px;
  width: 60%;
  content: "";
  position: absolute;
  top: 100%;
  margin-top: 30px;
  background-color: transparent;
  border-radius: 100%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-filter: blur(15px);
  -o-filter: blur(15px);
  filter: blur(15px);
}
.fn_cs_slider .slider_top .active .item_in {
  transform: scaleX(1) scaleY(1) rotateY(0);
  scale: 1.155;
  border: 2px solid #FFC007;
}
.fn_cs_slider .slider_top .prev .item_in,
.fn_cs_slider .slider_top .prev2 .item_in {
  transform: scaleX(1.05) scaleY(0.85) rotateY(-30deg);
}
.fn_cs_slider .slider_top .next .item_in,
.fn_cs_slider .slider_top .next2 .item_in {
  transform: scaleX(1.05) scaleY(0.85) rotateY(30deg);
}
.fn_cs_slider .slider_top li.active {
  left: 33.3333%;
}

@media (max-width: 1400px) {
  .fn_cs_slider .slider_top .active .item_in {
    transform: scaleX(1) scaleY(1) rotateY(0);
    scale: 1.08;
    border: 2px solid #FFC007;

  }
  .fn_cs_about .left_part,
  .fn_cs_steps[data-cols="4"] li {
    width: 50%;
  }
  .metaportal_fn_wsidebar .sidebar_left {
    padding-right: 25px;
  }
  .metaportal_fn_wsidebar .sidebar_right {
    padding-left: 25px;
  }
  .fn__gradient_title,
  .fn__maintitle.big {
    font-size: 40px;
  }
  .fn_cs_slider .slider_top li.prev {
    left: -30px;
  }
  .fn_cs_slider .slider_top li.next {
    margin-left: 30px;
  }

}


@media (max-width: 1023px) {
  .fn_cs_slider .slider_top .active .item_in {
    transform: scaleX(1) scaleY(1) rotateY(0);
    scale: 1;
    border: 2px solid #FFC007;
  }
  .fn_cs_contact_form li,
  .fn_cs_slider[data-responsive="on"] .slider_top li,
  .fn_cs_steps[data-cols="4"] li,
  .metaportal_fn_bloglist .bloglist li,
  .metaportal_fn_drops .grid > li,
  .metaportal_fn_leftnav,
  .metaportal_fn_result_list .metaportal_fn_drops .grid > li,
  .metaportal_fn_walletbox {
    width: 100%;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top > img {
    width: 100% !important;
    min-width: 100% !important;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 12%;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.prev {
    left: -100%;
    margin-left: -100px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.next {
    left: 100%;
    margin-left: 100px;
  }
  .fn_cs_slider .slider_top li.next {
    margin-left: 10px;
  }
  .fn_cs_slider .slider_top li.prev {
    left: -10px;
  }
  .fn_cs_slider .slider_top .img {
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
  }
  .fn_cs_slider  img {
    min-width: 100%;
    opacity: 0;
    max-height: 314px;
  }
  .fn_cs_slider .slider_top .item {
    perspective: 1000px;
    perspective-origin: 50% 50%;
    max-width: 438px;
  }
}
@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 3%;
  }
}
@media (max-width: 375px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 0%;
  }
}
@media (min-width: 666px) and (max-width: 766px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 18%;
  }
}
@media (min-width: 561px) and (max-width: 665px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 13%;
  }
}
@media (min-width: 491px) and (max-width: 560px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 5%;
  }
}
@media (min-width: 320px) and (max-width: 490px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li.active {
    left: 1%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding: 0 10px;
  }
  .fn_cs_slider[data-responsive="on"] .slider_top li,img {
    left: 5%;
    object-fit: cover ;
  }
}



